import React, {useState, useEffect} from "react";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import {Navigate} from "react-router-dom";
import Appbar from "./Appbar";
import {
    Autocomplete,
    Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, Grid, IconButton, Stack, styled, Table,
    TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography
} from "@mui/material";
import MenuList from "./MenuList";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const CreateFactor = () => {
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [loading, setLoading] = useState(true);
    const [factorCode, setFactorCode] = useState("");
    const [factorItemList, setFactorItemList] = useState([]);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [productList, setProductList] = useState([]);
    const [update, setUpdate] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [dialogProductList, setDialogProductList] = useState(false);
    const [productName, setProductName] = useState("");
    const [productAmount, setProductAmount] = useState("");
    const [productId, setProductId] = useState("");
    const [productStock, setProductStock] = useState("");
    const [price, setPrice] = useState("");
    const [productCode, setProductCode] = useState("");
    const [desc, setDesc] = useState("");
    const [search, setSearch] = useState("");
    const data = {
        token: localStorage.getItem("adminlogintoken"),
        factorcode: factorCode,
    }
    useEffect(() => {
        axios.post("https://tinpod.ir/api/get_config.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        setFactorCode(response.data.factorcode);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("Connection is lost");
            })
    }, [])
    useEffect(() => {
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            factorcode: factorCode,
        }
        axios.post("https://tinpod.ir/api/get_customerlist.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setCustomerList(response.data.data)
                    } else {
                        localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
        axios.post("https://tinpod.ir/api/get_factoritemlist.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        setFactorItemList(response.data.data);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("Connection is lost");
            })
        setUpdate(false);
    }, [update, factorCode])
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    const handle_selectproduct = () => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            search: search,
        }
        axios.post("https://tinpod.ir/api/get_productlist.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setProductList(response.data.data);
                        setDialogProductList(true);
                    } else {
                        //localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    useEffect(() => {
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            search: search,
        }
        axios.post("https://tinpod.ir/api/get_productlist.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setProductList(response.data.data);
                    } else {
                        //localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }, [search])
    const handle_btn_addtofactor = () => {
        let newprice = price.replace(/,/g, '');
        let newquantity = productAmount.replace(/,/g, '');
        if (!!customerName && !!customerPhone) {
            if (!!factorCode) {
                setLoading(true);
                const data = {
                    token: localStorage.getItem("adminlogintoken"),
                    factorcode: factorCode,
                    customername: customerName,
                    customerphone: customerPhone,
                    product: productName,
                    quantity: newquantity,
                    productid: productId,
                    price: newprice,
                    productcode: productCode,
                    desc: desc,
                }
                axios.post("https://tinpod.ir/api/insert_factor.php", data)
                    .then(response => {
                        if (response.status === 200) {
                            setLoading(false);
                            if (response.data.error === false) {
                                setSuccsMsg(response.data.message);
                                setUpdate(true);
                                setProductCode("");
                                setProductId("");
                                setProductName("");
                                setProductStock("");
                                setProductAmount("");
                                setPrice("");
                            } else
                                setErrorMsg(response.data.message);
                        } else {
                            setLoading(false);
                            setErrorMsg("Connection is lost");
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        setErrorMsg("Connection is lost");
                    })
            } else {
                setErrorMsg("شماره فاکتور نمی تواند خالی باشد");
            }
        } else {
            setErrorMsg("قبل از وارد کردن محصول مشتری را انتخاب کنید");
        }
    }
    const handle_btn_savefactor = () => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            factorcode: factorCode,
        }
        axios.post("https://tinpod.ir/api/save_factor.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("Connection is lost");
            })
    }
    const handle_btndelete_click = (id) => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            productid: id,
        }
        axios.post("https://tinpod.ir/api/deletefactoritem.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setUpdate(true);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("Connection is lost");
            })
    }

    function toEnglishDigits(str) {
        // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
        var e = '۰'.charCodeAt(0);
        str = str.replace(/[۰-۹]/g, function (t) {
            return t.charCodeAt(0) - e;
        });

        // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
        e = '٠'.charCodeAt(0);
        str = str.replace(/[٠-٩]/g, function (t) {
            return t.charCodeAt(0) - e;
        });
        return str;
    }

    function number_format(number) {
        let pe = toEnglishDigits(number);
        pe = String(pe).replace(/,/g, '');
        let nf = new Intl.NumberFormat("en-US");
        if (nf.format(pe) !== "NaN")
            return (nf.format(pe));
    }

    return (
        <div>
            {localStorage.getItem("adminlogintoken") === "" ? <Redirect/> : ''}
            {localStorage.getItem("adminlogintoken") === null ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <Box dir={"rtl"}>
                        <Grid container spacing={1} sx={{marginTop: 0, padding: 1, backgroundColor: "#ececec"}}>
                            <Grid item xs={12} md={2}>
                                <Stack direction={"row"}>
                                    <Typography sx={{marginTop: 1, marginLeft: 1}}>
                                        کد پیگیری :
                                    </Typography>
                                    <Typography sx={{marginTop: 1}}>
                                        {factorCode}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    freeSolo
                                    disabled={factorItemList.length > 0}
                                    options={customerList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={
                                        (event, customerlist) => {
                                            try {
                                                setCustomerName(customerlist.name);
                                                setCustomerPhone(customerlist.phone);
                                            } catch (ex) {
                                                setCustomerName("");
                                                setCustomerPhone("");
                                            }
                                        }}
                                    onInputChange={(e) => {
                                        let value = e.target.value;
                                        if (!!value && value !== "0" && value !== "undefined")
                                            setCustomerName(value);
                                    }}
                                    fullWidth
                                    size={"small"}
                                    renderInput={(params) =>
                                        <TextField {...params} placeholder="نام مشتری"/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled={factorItemList.length > 0}
                                    placeholder={"شماره تلفن"}
                                    fullWidth
                                    value={customerPhone}
                                    type="number"
                                    onChange={(e) => {
                                        setCustomerPhone(e.target.value)
                                    }}
                                    size={"small"}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <div style={{direction: "rtl", backgroundColor: "#ececec", paddingRight: "7px"}}>محصول</div>
                    <Box dir={"rtl"}>
                        <Grid container spacing={1} sx={{marginTop: 0, backgroundColor: "#ececec"}}>
                            <Grid item xs={12} md={3} sx={{marginRight: 1, marginLeft: 1}}>
                                <TextField
                                    placeholder={"نام محصول"}
                                    fullWidth
                                    value={productName}
                                    onClick={() => {
                                        handle_selectproduct()
                                    }}
                                    type="text"
                                    size={"small"}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{marginRight: 1, marginLeft: 1}}>
                                <TextField
                                    placeholder={"تعداد"}
                                    fullWidth
                                    value={productAmount}
                                    size={"small"}
                                    onChange={(e) => setProductAmount(number_format(e.target.value))}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{marginRight: 1, marginLeft: 1}}>
                                <TextField
                                    placeholder={"قیمت ( ریال )"}
                                    fullWidth
                                    value={price}
                                    size={"small"}
                                    onChange={(e) => setPrice(number_format(e.target.value))}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{marginRight: 1, marginLeft: 1}}>
                                <TextField
                                    placeholder={"کد شناور"}
                                    fullWidth
                                    value={productCode}
                                    type="number"
                                    size={"small"}
                                    onChange={(e) => setProductCode(e.target.value)}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Typography

                                    sx={{marginTop: 1, marginRight: 1, fontSize: "12px",color: productStock < 0  ? "#fc5757" : "black"}}>
                                    موجودی : <span dir={"ltr"}>{productStock.toLocaleString()}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{marginRight: 1, marginLeft: 1}}>
                                <TextField
                                    placeholder={"توضیحات"}
                                    fullWidth
                                    disabled={factorItemList.length > 0}
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                    type="text"
                                    size={"small"}
                                    autoComplete="off"
                                    variant="outlined"/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button
                                    variant="contained"
                                    size={"large"}
                                    onClick={handle_btn_addtofactor}
                                    sx={{
                                        marginRight: 1,
                                        backgroundColor: "gold", '&:hover': {
                                            backgroundColor: "#dedede"
                                        }, marginTop: "-2px", marginBottom: 2, color: "#000000"
                                    }}>افزودن به فاکتور، ثبت و دخیره</Button>
                                <Button
                                    variant="contained"
                                    size={"large"}
                                    onClick={handle_btn_savefactor}
                                    sx={{
                                        backgroundColor: "gold", '&:hover': {
                                            backgroundColor: "#dedede"
                                        }, marginTop: "-2px", marginBottom: 2, color: "#000000", marginRight: 1
                                    }}>به مشتری اس ام اس ارسال کنید</Button>
                            </Grid>
                        </Grid>
                        <div style={{margin: "8px"}}>
                            <TableContainer>
                                <Table
                                    sx={{minWidth: 250, maxHeight: '80%', overflowY: 'auto'}}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align={"right"}>
                                                {"کد"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"نام کالا"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"تعداد"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"قیمت واحد ( ریال )"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"left"}>
                                                {"عملیات"}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {factorItemList !== "" ?
                                        <TableBody>
                                            {factorItemList.map(list => (
                                                <>
                                                    <StyledTableRow
                                                        key={list.id}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <StyledTableCell align={"right"}>
                                                            {list.productid}
                                                        </StyledTableCell>
                                                        <StyledTableCell align={"right"}>
                                                            {list.productname}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" align={"right"}>
                                                            {list.quantity}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" align={"right"}>
                                                            {list.price}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            component="th" scope="row" align={"left"}>
                                                            <IconButton
                                                                onClick={() => handle_btndelete_click(list.id)}
                                                                aria-label="delete">
                                                                <DeleteIcon sx={{color: "red"}}/>
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>

                                                </>
                                            ))}
                                        </TableBody>
                                        : ''}
                                </Table>
                            </TableContainer>
                        </div>

                    </Box>
                </Grid>

                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={dialogProductList}
                onClose={() => setDialogProductList(false)}
                scroll="paper">
                <DialogContent dividers={'paper'}>
                    <DialogContentText tabIndex={-1}>
                        <Box dir={"rtl"}>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={"جست و جو بر اساس نام کالا"}
                                size={"small"}
                                sx={{marginBottom: 2}}
                                variant="outlined"/>
                            <TableContainer>
                                <Table
                                    sx={{minWidth: 250, maxHeight: '80%', overflowY: 'auto'}}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align={"right"}>
                                                {"نام کالا"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"موجودی (تعداد)"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"موجودی در راه (تعداد)"}
                                            </StyledTableCell>
                                            <StyledTableCell align={"right"}>
                                                {"مانده"}
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {productList !== "" ?
                                        <TableBody dir={"ltr"}>
                                            {productList.map(list => (
                                                <StyledTableRow
                                                    onClick={() => {
                                                        setProductName(list.name);
                                                        setDialogProductList(false);
                                                        setProductId(list.id);
                                                        setProductStock(list.remained);
                                                        setProductCode(list.productcode);
                                                    }}
                                                    key={list.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {border: 0},
                                                        cursor: "pointer"
                                                    }}>
                                                    <StyledTableCell align={"right"}>
                                                        {list.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{backgroundColor: list.stock < 0 ? "#fc9494" : ""}}
                                                        component="th" scope="row" align={"right"}>
                                                        {list.stock.toLocaleString()}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{backgroundColor: list.stockinway < 0 ? "#fc9494" : ""}}
                                                        component="th" scope="row" align={"right"}>
                                                        {list.stockinway.toLocaleString()}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{backgroundColor: list.remained < 0 ? "#fc9494" : ""}}
                                                        component="th" scope="row" align={"right"}>
                                                        {list.remained.toLocaleString()}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                        : ''}
                                </Table>
                            </TableContainer>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default CreateFactor;
