import React,{useEffect,useState} from "react";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import {Button, CircularProgress, Dialog, Grid, Stack, TextField, Typography} from "@mui/material";
import Appbar from "./Appbar";
import MenuList from "./MenuList";
import {Navigate} from "react-router-dom";
import axios from "axios";

const CreateUser =()=>{
    const [loading , setLoading] = useState(false);
    const [userName , setUserName] = useState("");
    const [userPassword , setUserpassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [name , setName] = useState("");
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    const handle_btn_createuser=()=>{
        setLoading(true);
        const data={
            username : userName,
            password : userPassword,
            token : localStorage.getItem("adminlogintoken"),
            name : name,
        }
        axios.post("https://tinpod.ir/api/adduser.php",data)
            .then(response=>{
                setLoading(false);
                if (response.status === 200)
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setUserName("");
                        setUserpassword("");
                    }
                    else
                        setErrorMsg(response.data.message);
                else
                    setErrorMsg(response.data.message);
            })
            .catch(error=>{
                setLoading(false);
                setErrorMsg("ارتباط با سرور قطع شده است");
            })
    }
    return(
        <div>
            {localStorage.getItem("adminlogintoken") === "" ? <Redirect/> : ''}
            {localStorage.getItem("adminlogintoken") === null ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <div dir={"rtl"} style={{
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        marginBottom: "60px",
                        marginTop: "20px",
                        display: "flex", justifyContent: "center", justifyItems: "center"
                    }}>
                        <Stack sx={{maxWidth: "500px",}}>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                                marginBottom:2
                            }}>
                                توجه کنید که کاربران ایجاد شده در این بخش سطح درسترسی ادمین را ندارند !
                            </Typography>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                نام و نام خانوادگی
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                type={"text"}
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                نام کاربری
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                value={userName}
                                onChange={(e)=>setUserName(e.target.value)}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                رمز عبور
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                type={"text"}
                                value={userPassword}
                                onChange={(e)=>setUserpassword(e.target.value)}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Button
                                variant="contained"
                                fullWidth
                                size={"large"}
                                onClick={handle_btn_createuser}
                                sx={{
                                    backgroundColor: "gold", '&:hover': {
                                        backgroundColor: "#dedede"
                                    }, marginTop: 2, color: "#000000"
                                }}>افزودن کاربر جدید</Button>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default CreateUser;