import React, {useState, useEffect} from "react";
import Appbar from "./Appbar";
import {
    Box,
    Button,
    CircularProgress,
    Dialog, FormControl,
    Grid,
    IconButton,
    InputAdornment, InputLabel, MenuItem, Select, styled,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow,
    TextField, Typography
} from "@mui/material";
import MenuList from "./MenuList";
import DatePicker from "react-multi-date-picker";
import DateRangeIcon from '@mui/icons-material/DateRange'
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";
import "react-multi-date-picker/styles/layouts/mobile.css"
import moment from 'jalali-moment'
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
let totalq = 0;
let totalprice =0;
const Report = () => {
    const [dateAz, setDateAz] = useState("");
    const [dateTa, setDateTa] = useState("");
    const [reportList, setReportList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [selectUser, setSelectUser] = useState("");
    const [factorCode, setFactorCode] = useState("");
    const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    useEffect(() => {
        const data = {
            token: localStorage.getItem("adminlogintoken"),
        }
        axios.post("https://tinpod.ir/api/get_userlist.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false)
                        setUserList(response.data.data);
                    else {
                        localStorage.clear();
                        window.location.href = "/login";
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }, [])
    const handle_click_report = () => {
        totalq = 0;
        totalprice = 0;
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            startdate: dateAz,
            enddate: dateTa,
            user: selectUser,
            factorcode: factorCode,
        }
        axios.post("https://tinpod.ir/api/get_report.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200)
                    if (response.data.error === false) {
                        setReportList(response.data.data);
                        response.data.data.map((item)=>(
                            item.items.map((list)=>{
                                totalq = parseInt(totalq) + parseInt(list.quantity);
                                totalprice = parseInt(totalprice) + parseInt(list.price);
                            })
                        ))
                    } else
                        setErrorMsg(response.data.message);
                else
                    setErrorMsg(response.data.message);
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("ارتباط با سرور قطع شده است");
            })

    }
    return (
        <div>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <Box sx={{padding: 2}} dir={"rtl"}>
                        <Grid container spacing={1} sx={{marginTop: 0}}>
                            <Grid item xs={12} md={3}>
                                <div style={{marginLeft: "10px"}}>
                                    <DatePicker
                                        type="custom"
                                        digits={digits}
                                        className="rmdp-mobile bg-dark yellow"
                                        fixMainPosition={true}
                                        onChange={(date) => {
                                            date.isValid ? setDateAz(String(date)) : setDateAz("");
                                        }}
                                        format={"YYYY/MM/DD"}
                                        style={{marginLeft: "10px", marginRight: "10px"}}
                                        render={(stringDate, openCalendar) => {
                                            return (
                                                <div>
                                                    <TextField
                                                        placeholder="از تاریخ"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={dateAz}
                                                        name="numberformat"
                                                        variant="outlined"
                                                        size={"small"}
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        onClick={openCalendar}>
                                                                        <DateRangeIcon/>
                                                                    </IconButton>
                                                                    {!!dateAz ?
                                                                        <IconButton
                                                                            onClick={() => setDateAz("")}>
                                                                            <CloseIcon/>
                                                                        </IconButton>
                                                                        : ""}
                                                                </InputAdornment>,
                                                        }}
                                                        sx={{
                                                            marginLeft: "10px"
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }}
                                        calendar={persian}
                                        locale={persian_fa}
                                        calendarPosition="bottom-right"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div>
                                    <DatePicker
                                        type="custom"
                                        digits={digits}
                                        className="rmdp-mobile bg-dark yellow"
                                        fixMainPosition={true}
                                        onChange={(date) => {
                                            date.isValid ? setDateTa(String(date)) : setDateTa("");
                                        }}
                                        render={(stringDate, openCalendar) => {
                                            return (
                                                <div>
                                                    <TextField
                                                        placeholder="تا تاریخ"
                                                        fullWidth
                                                        autoComplete="off"
                                                        value={dateTa}
                                                        name="numberformat"
                                                        variant="outlined"
                                                        size={"small"}
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={openCalendar}>
                                                                        <DateRangeIcon/>
                                                                    </IconButton>
                                                                    {!!dateTa ?
                                                                        <IconButton
                                                                            onClick={() => setDateTa("")}>
                                                                            <CloseIcon/>
                                                                        </IconButton>
                                                                        : ""}
                                                                </InputAdornment>,
                                                        }}
                                                    />
                                                </div>

                                            )
                                        }}
                                        calendar={persian}
                                        locale={persian_fa}
                                        calendarPosition="bottom-right"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <Select
                                        value={selectUser}
                                        size={"small"}
                                        onChange={(e) => setSelectUser(e.target.value)}>
                                        {userList.map((item) => (
                                            <MenuItem value={item.user}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    color={"info"}
                                    autoComplete="off"
                                    placeholder="شماره پیگیری"
                                    fullWidth
                                    type={"number"}
                                    value={factorCode}
                                    onChange={(e) => setFactorCode(e.target.value)}
                                    size={"small"}
                                    sx={{
                                        marginBottom: 1,
                                    }}
                                    variant="outlined"/>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size={"large"}
                                    onClick={handle_click_report}
                                    sx={{
                                        backgroundColor: "gold", '&:hover': {
                                            backgroundColor: "#dedede"
                                        }, color: "#000000"
                                    }}>تهیه گزارش</Button>
                            </Grid>
                        </Grid>
                        <TableContainer sx={{marginTop: 2}}>
                            <Table
                                sx={{minWidth: 250, maxHeight: '80%', overflowY: 'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"right"}>
                                            {"مشتری"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"شماره پیگیری"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"فروشنده"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"تاریخ"}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {reportList !== "" ?
                                    <TableBody>
                                        {reportList.map(list => (
                                            <>
                                                <StyledTableRow key={list.id}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                    <StyledTableCell align={"right"}>
                                                        {list.customer}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align={"right"}>
                                                        {list.code}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align={"right"}>
                                                        {list.user}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align={"right"}>
                                                        {list.date}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                {list.items.map(list1 => (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={1}
                                                            component="th"
                                                            scope="row" align={"left"}>
                                                            کد کالا : {list1.productid}
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={1}
                                                            component="th"
                                                            scope="row" align={"left"}>
                                                            نام کالا : {list1.productname}
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={1}
                                                            component="th"
                                                            scope="row" align={"left"}>
                                                            تعداد فروش در این فاکتور : {list1.quantity}
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={1}
                                                            component="th"
                                                            scope="row" align={"left"}>
                                                            قیمت ( ریال ) : {parseInt(list1.price).toLocaleString()}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        component="th"
                                                        scope="row" align={"right"}>
                                                        توضیحات : {list.desc}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                    : ''}
                            </Table>
                        </TableContainer>
                        {reportList.length > 0 ?
                            <Typography
                                sx={{marginTop : 3 , padding:2 , borderRadius :"7px" , border:"1px solid black" , textAlign:"center"}}>
                                <span style={{marginLeft:"10px",marginRight:"10px"}}>  جمع کل تعداد محصول فروش رفته ( بر اساس فیلتر انتخابی ) : {totalq.toLocaleString()}</span>
                              جمع کل مبلغ فروش ( بر اساس فیلتر انتخابی ) : {totalprice.toLocaleString()}
                            </Typography>
                        : ""}
                    </Box>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>

            </Grid>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default Report;