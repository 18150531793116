import React,{useState,useEffect} from "react";
import {Button, Card, CardActions, CardContent, CircularProgress, Dialog, TextField, Typography} from "@mui/material";
import * as PropTypes from "prop-types";
import {Navigate} from "react-router-dom";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import bg from './img/bg.jpg'

const Login=()=>{
    const [errorMsg, setErrorMsg] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const handle_btn_login = () => {
        setLoading(true);
        const data = {
            username: username,
            password: password,
        }
        axios.post("https://tinpod.ir/api/login.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        localStorage.setItem("adminlogin", true);
                        localStorage.setItem("adminlogintoken", response.data.token);
                        setLogin(true);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("Connection is lost");
            })
    }
    const Redirect = () => {
        return (<Navigate replace to="/"/>)
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "100vh",
                backgroundImage: "url(" + bg + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
            {login ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            <Card sx={{minWidth: 300, padding: 2, margin: 2,backgroundColor:"rgba(255,255,255,0.76)"}} elevation={3}>
                <CardContent>
                    <h1 style={{fontWeight: "bold", width: "100%", textAlign: "center"}}>خوش آمدید</h1>
                    <Typography component={"h4"} sx={{mb: 1.5, marginTop: 1, textAlign: "center"}} color="text.secondary">
                        لطفا نام کاربری و رمز عبور خود را وارد کنید
                    </Typography>
                    <Typography variant="body2">
                        <TextField
                            placeholder="نام کاربری"
                            fullWidth
                            type="text"
                            autoComplete="off"
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                            variant="outlined"/>
                        <TextField
                            placeholder={"رمز عبور"}
                            fullWidth
                            type="password"
                            autoComplete="off"
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            sx={{marginTop: 1}}
                            variant="outlined"/>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handle_btn_login}
                        size="large">ورود</Button>
                </CardActions>
            </Card>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>

        </div>
    )
}
export default Login;