import React, {useEffect, useState} from "react";
import {
    AppBar, BottomNavigation,
    Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
    IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, styled,
    SwipeableDrawer, TextField, Toolbar,
} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import {Link} from "react-router-dom";
import axios from "axios";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import {grey} from "@mui/material/colors";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: gold;
  }
`);

const Appbar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [role, setRole] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [adminDrawer, setAdminDrawer] = useState(false);
    useEffect(() => {
        const data = {
            token : localStorage.getItem("adminlogintoken"),
        }
        axios.post("https://tinpod.ir/api/userinfo.php",data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setRole(response.data.role);
                    }
                }
            })
            .catch(error => {
            })
    }, [])
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const Puller = styled(Box)(({theme}) => ({
        width: 30,
        height: 6,
        backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
        borderRadius: 3,
        position: 'absolute',
        top: 10,
        left: 'calc(50% - 15px)',
    }));


    return (
        <Box sx={{flexGrow: 1, marginTop: "-16px"}}>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <AppBar position="static" sx={{backgroundColor: "#2b2b2b"}}>
                <Toolbar variant="dense">
                    <Box dir={"rtl"}>
                        <IconButton size="small" color="inherit">
                            <IconButton
                                size="small"
                                onClick={handleMenu}
                                color="inherit">
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                            >
                                <MenuItem sx={{fontSize: "14px"}} onClick={() => {
                                    localStorage.clear()
                                    window.location.href = '/login';
                                }}>خروج از حساب کاربری</MenuItem>
                            </Menu>
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: {xs: 'flex', md: 'none'},
                        position: "absolute",
                        right: 0,
                        marginTop: 2
                    }}
                         dir={"rtl"}>
                        <Stack direction={"row"} spacing={3} sx={{
                            marginRight: 2,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 3
                        }}>
                        </Stack>
                        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0,zIndex:"1000"}} elevation={3}>
                            <BottomNavigation
                                sx={{backgroundColor: "#2b2b2b"}}
                                showLabels>
                                <BottomNavigationAction
                                    component={Link}
                                    to="/"
                                    xs={{color: "white"}}
                                    label="داشبورد"
                                    icon={<DashboardIcon/>}
                                />
                                <BottomNavigationAction
                                    component={Link}
                                    to="/createfactor"
                                    xs={{color: "white"}}
                                    label="ثبت فاکتور جدید"
                                    icon={<PostAddIcon/>}
                                />
                                {role === "admin" ?
                                    <BottomNavigationAction
                                        onClick={() => setAdminDrawer(true)}
                                        xs={{color: "white"}}
                                        label="ادمین"
                                        icon={
                                        <AdminPanelSettingsIcon/>
                                    }/>
                                    : ""}
                            </BottomNavigation>
                        </Paper>
                    </Box>
                </Toolbar>
            </AppBar>
            <Dialog
                open={false}
                onClose
                scroll="paper">
                <DialogContent dividers={'paper'}>
                    <DialogContentText tabIndex={-1}>
                        <Box dir={"rtl"}>
                            <div style={{color: "white"}}>متن پیام را وارد کنید</div>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                placeholder={"پیغام"}
                                onChange
                                size={"small"}
                                sx={{
                                    input: {
                                        color: "white",
                                        textAlign: "center",
                                        border: "1px solid #dedede",
                                        borderRadius: "7px",
                                        backgroundColor: "#383838",
                                    },
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: "white"}} >بازگشت</Button>
                    <Button sx={{color: "white"}} >ثبت</Button>
                </DialogActions>
            </Dialog>
            <SwipeableDrawer
                anchor="bottom"
                open={adminDrawer}
                disableSwipeToOpen="false"
                PaperProps={{
                    sx: {
                        backgroundColor: "#494747",
                        color: "white",
                        borderRadius: "7px 7px 0 0"
                    }
                }}
                onClose={() => {
                    setAdminDrawer(false)
                }}
            >
                <Puller/>
                <Box
                    sx={{
                        textAlign: "center",
                        marginTop: 3,
                        marginBottom: 3,

                    }}
                    role="presentation"
                    onClick={() => {
                        setAdminDrawer(false)
                    }}
                    onKeyDown={() => {
                        setAdminDrawer(false)
                    }}>
                    <List sx={{textAlign: "center"}}>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/mngcustomer"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="مدیریت مشتری ها"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/addcustomer"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="افزودن مشتری"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/mngproduct"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="مدیریت کالا ها"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/addproduct"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="افزودن کالای جدید"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/mnguser"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="مدیریت کاربران"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/adduser"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="ایجاد کاربر جدید"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem key="1" disablePadding>
                            <ListItemButton components={Link} to={"/report"}>
                                <ListItemText
                                    sx={{textAlign: "center"}}
                                    primary="گزارشات"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </SwipeableDrawer>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </Box>
    )
}
export default Appbar