import React,{useState,useEffect} from "react";
import {Button, CircularProgress, Dialog, Grid, Stack, TextField, Typography} from "@mui/material";
import Appbar from "./Appbar";
import MenuList from "./MenuList";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";

const AddNewProduct=()=>{
    const [loading , setLoading] = useState(false);
    const [productName , setProductName] = useState("");
    const [stock , setStock] = useState("");
    const [stockInway , setStockInway] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [productId , setProductId] = useState("");
    const handle_btn_addproduct=()=>{
        setLoading(true);
        let newstock = stock.replace(/,/g, '');
        let newstockInway = stockInway.replace(/,/g, '');

        const data={
            name : productName,
            stock : newstock,
            stockinway : newstockInway,
            token : localStorage.getItem("adminlogintoken"),
            productid : productId,
        }
        axios.post("https://tinpod.ir/api/addproduct.php",data)
            .then(response=>{
                setLoading(false);
                if (response.status === 200)
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setProductName("");
                        setStock("");
                        setStockInway("");
                    }
                    else
                        setErrorMsg(response.data.message);
                else
                    setErrorMsg(response.data.message);
            })
            .catch(error=>{
                setLoading(false);
                setErrorMsg("ارتباط با سرور قطع شده است");
            })
    }
    function toEnglishDigits(str) {
        // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
        var e = '۰'.charCodeAt(0);
        str = str.replace(/[۰-۹]/g, function(t) {
            return t.charCodeAt(0) - e;
        });

        // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
        e = '٠'.charCodeAt(0);
        str = str.replace(/[٠-٩]/g, function(t) {
            return t.charCodeAt(0) - e;
        });
        return str;
    }
    function number_format(number){
        let pe=toEnglishDigits(number);
        pe = String(pe).replace(/,/g, '');
        let nf = new Intl.NumberFormat("en-US");
        if (nf.format(pe) !== "NaN")
            return (nf.format(pe));
    }
    return(
        <div>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <div dir={"rtl"} style={{
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        marginBottom: "60px",
                        marginTop: "20px",
                        display: "flex", justifyContent: "center", justifyItems: "center"
                    }}>
                        <Stack sx={{maxWidth: "500px",}}>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                کد کالا
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                type={"number"}
                                fullWidth
                                value={productId}
                                onChange={(e)=>setProductId(e.target.value)}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                نام کالا
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                value={productName}
                                onChange={(e)=>setProductName(e.target.value)}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                موجودی
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                value={stock}
                                onChange={(e)=>setStock(number_format(e.target.value))}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                                variant="outlined"/>
                            <Typography sx={{
                                textAlign: "right",
                                color: "black",
                                fontSize: "15px",
                            }}>
                                موجودی در راه
                            </Typography>
                            <TextField
                                color={"info"}
                                autoComplete="off"
                                fullWidth
                                value={stockInway}
                                onChange={(e)=>setStockInway(number_format(e.target.value))}
                                size={"small"}
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,

                                }}
                                variant="outlined"/>
                            <Button
                                variant="contained"
                                fullWidth
                                size={"large"}
                                onClick={handle_btn_addproduct}
                                sx={{
                                    backgroundColor: "gold", '&:hover': {
                                        backgroundColor: "#dedede"
                                    }, marginTop: 2, color: "#000000"
                                }}>افزودن محصول جدید</Button>
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default AddNewProduct;